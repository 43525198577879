import { useEffect, useRef, useState } from "react";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import airports from '../Data/AirportList.json';
export default function SearchMultiCity() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [isDomestic, setIsDomestic] = useState('DOMESTIC');
    const [preffered, setPreferred] = useState("REGULAR");
    const [locationFrom, setLocationFrom] = useState('HYD');
    const [locationTo, setLocationTo] = useState('BOM');
    const [displayFrom, setDisplayFrom] = useState('Hyderabad(HYD)');
    const [displayTo, setDisplayTo] = useState('Mumbai(BOM)');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [cityTo, setCityTo] = useState('Mumbai');
    const [trips, setTrips] = useState([
        { from: "HYD", to: "BOM, India", locationFrom: "HYD", locationTo: "BOM", displayFrom: "Hyderabad(HYD)", displayTo: "Mumbai(BOM)", cityFrom: "Hyderabad", cityTo: "Mumbai", departureDate: new Date(), departureResults: [], arrivalResults: [] },
    ]);
    const [departureDate, setDepartureDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [infants, setInfants] = useState(0);
    const [cabinClass, setCabinClass] = useState("ECONOMY");
    const [errors, setErrors] = useState({});
    const [from, setFrom] = useState('Hyderabad, India');
    const [to, setTo] = useState('Mumbai, India');
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        temp.displayTo = displayTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    function getFlightRequests() {
        const fvalues = [];
        for (var i = 0; i < trips.length; i++) {
            fvalues.push({ from: trips[i].locationFrom, to: trips[i].locationTo, locationFrom: trips[i].locationFrom, locationTo: trips[i].locationTo, displayFrom: trips[i].displayFrom, displayTo: trips[i].displayTo, cityFrom: trips[i].cityFrom, cityTo: trips[i].cityTo, departureDate: moment(trips[i].departureDate).format('YYYY-MM-DD') });
        }
        return fvalues;
    }
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "journeyType": "MULTICITY",
                "locationFrom": locationFrom,
                "locationTo": locationTo,
                "displayFrom": displayFrom,
                "displayTo": displayTo,
                "adults": adults,
                "kids": kids,
                "infants": infants,
                "cabinClass": cabinClass,
                "departureDate": moment(departureDate).format('YYYY-MM-DD'),
                "returnDate": moment(departureDate).format('YYYY-MM-DD'),
                "cityFrom": cityFrom,
                "cityTo": cityTo,
                "userId": userId,
                "from": from,
                "to": to,
                "isOneWay": false,
                "isDomestic": isDomestic,
                "requestedBy": "CUSTOMER",
                "flightTravelerPoints": getFlightRequests()
            }
            console.log(searchQuery)
            if (isDomestic === "INTERNATIONAL") {
                navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
            }
            else {
                navigate('/multicity-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
            }
        }
    }
    const addTrip = () => {
        setTrips([...trips, { from: "HYD", to: "BOM", locationFrom: "HYD", locationTo: "BOM", displayFrom: "Hyderabad(HYD)", displayTo: "Mumbai(BOM)", cityFrom: "Hyderabad", cityTo: "Mumbai", departureDate: new Date(), departureResults: [], arrivalResults: [] }]);
    };

    const removeTrip = (index) => {
        const updatedTrips = trips.filter((_, i) => i !== index);
        setTrips(updatedTrips);
    };
    const handleDepartureAirportSearch = (index, value) => {
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            const updatedTrips = [...trips];
            updatedTrips[index].departureResults = filteredAirports;
            updatedTrips[index].locationFrom = value;
            updatedTrips[index].from = value;
            updatedTrips[index].displayFrom = value;
            updatedTrips[index].cityFrom = value;
            setTrips(updatedTrips);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                const updatedTrips = [...trips];
                updatedTrips[index].departureResults = filteredAirports1;
                updatedTrips[index].locationFrom = value;
                updatedTrips[index].from = value;
                updatedTrips[index].displayFrom = value;
                updatedTrips[index].cityFrom = value;
                setTrips(updatedTrips);
            }
        }
    };
    const handleDepartureAirportClick = (index) => {
        const updatedTrips = [...trips];
        updatedTrips[index].departureResults = airports;
        updatedTrips[index].arrivalResults = [];
        setTrips(updatedTrips);
    };
    const handleDepartureSelect = (index, airport) => {
        if (airport.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        const updatedTrips = [...trips];
        updatedTrips[index].displayFrom = `${airport.cityName} (${airport.airportCode})`;
        updatedTrips[index].locationFrom = `${airport.airportCode}`;
        updatedTrips[index].from = `${airport.airportCode}`;
        updatedTrips[index].cityFrom = `${airport.cityName}`;
        updatedTrips[index].departureResults = [];
        setTrips(updatedTrips);
    };
    const handleArrivalAirportSearch = (index, value) => {
        const filteredAirports = airports.filter((airport) =>
            airport.airportCode.toLowerCase().includes(value.toLowerCase())
        );
        const updatedTrips = [...trips];
        updatedTrips[index].arrivalResults = filteredAirports;
        updatedTrips[index].displayTo = value;
        updatedTrips[index].to = value;
        updatedTrips[index].locationTo = value;
        updatedTrips[index].departureResults = [];
        setTrips(updatedTrips);
    };
    const handleArrivalAirportClick = (index) => {
        const updatedTrips = [...trips];
        updatedTrips[index].arrivalResults = airports;
        updatedTrips[index].departureResults = [];
        setTrips(updatedTrips);
    };

    const handleArrivalSelect = (index, airport) => {
        const updatedTrips = [...trips];
        if (airport.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        updatedTrips[index].displayTo = `${airport.cityName} (${airport.airportCode})`;
        updatedTrips[index].to = `${airport.airportCode}`;
        updatedTrips[index].locationTo = `${airport.airportCode}`;
        updatedTrips[index].cityTo = `${airport.cityName}`;
        updatedTrips[index].arrivalResults = [];
        setTrips(updatedTrips);
    };

    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleClassType = (e) => {
        setCabinClass(e.target.value)
    }
    const handlePrefferedType = (e) => {
        setPreferred(e.target.value)
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleIncreaseInfants = (e) => {
        setInfants(parseFloat(infants + 1))
    }
    const handleDecreaseInfants = (e) => {
        if (infants !== 0) {
            setInfants(parseFloat(infants - 1))
        }
    }
    const handleDepartureDate = (index, field, value) => {
        const updatedTrips = [...trips];
        updatedTrips[index][field] = value;
        setTrips(updatedTrips);
    };
    const addDays = (departureDate) => {
        if (departureDate === undefined) {
            departureDate = new Date();
        }
        const newDate = new Date(departureDate.getTime());
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        addDays(departureDate);
    }, [])
    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12">
                {trips.map((trip, index) => (
                    <div className="row gy-3 gx-lg-2 gx-3">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                            <div className="form-group hdd-arrow mb-0">
                                <h6 className="text-dark">FROM</h6>
                                <div className="position-relative">
                                    <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={trip.displayFrom} onChange={(e) => handleDepartureAirportSearch(index, e.target.value)} onClick={() => handleDepartureAirportClick(index)} onFocus={() => handleDepartureAirportClick(index)} />
                                    <span className="icon-inside">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={24} fill="#808080" viewBox="0 0 20 17"><path d="M1.376 16.498v-1h17v1h-17zm1.575-5.075L.676 7.498l.85-.2 1.8 1.55 5.4-1.45-4.05-6.85L5.75.223l6.85 6.125 5.225-1.4c.383-.1.73-.03 1.038.212.308.242.462.554.462.938 0 .266-.075.5-.225.7-.15.2-.35.333-.6.4L2.95 11.423z" /></svg>
                                    </span>
                                    {errors[index]?.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                    {trip.departureResults && trip.departureResults.length > 0 && (
                                        <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                            {trip.departureResults.map((airport) => (
                                                <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleDepartureSelect(index, airport)}>
                                                    <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                    <div className="item-details">
                                                        <span className="item-code-date">{airport.airportName}</span>
                                                        <span className="item-designation">{airport.countryName}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-auto px-1 d-flex align-items-center justify-content-center">
                            {/* SVG Icon Between the Textboxes */}
                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" viewBox="0 0 32 32" className="c-pointer">
                                <rect width={32} height={32} fill="#fff" rx={16} />
                                <g stroke="#3366CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" clipPath="url(#clip0_160_1650)">
                                    <path d="M24.167 14.833H7.833M7.833 14.833L13.667 9M7.833 18.334h16.334M24.167 18.333l-5.834 5.834" />
                                    <circle cx={16} cy={16} r="13.375" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_160_1650">
                                        <path fill="#fff" d="M0 0H28V28H0z" transform="translate(2 2)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-groupp hdd-arrow mb-0">
                                <h6 className="text-dark">TO</h6>
                                <div className="position-relative">
                                    <input ref={inputRRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={trip.displayTo} onChange={(e) => handleArrivalAirportSearch(index, e.target.value)} onClick={() => handleArrivalAirportClick(index)} onFocus={() => handleArrivalAirportClick(index)} />
                                    <span className="icon-inside">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#808080" viewBox="0 0 24 24"><mask id="mask0_160_1644" style={{ maskType: 'alpha' }} width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse"><path fill="#808080" d="M0 0H24V24H0z" /></mask><g mask="url(#mask0_160_1644)"><path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z" /></g></svg>
                                    </span>
                                    {trip.arrivalResults && trip.arrivalResults.length > 0 && (
                                        <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                            {trip.arrivalResults.map((airport) => (
                                                <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleArrivalSelect(index, airport)}>
                                                    <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                    <div className="item-details">
                                                        <span className="item-code-date">{airport.airportName}</span>
                                                        <span className="item-designation">{airport.countryName}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                {errors.displayTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                            <h6 className="text-dark">DEPATURE DATE</h6>
                            <div className="position-relative">
                                <ReactDatePicker autoComplete="false" className="form-control searchTextbox" wrapperClassName="datePicker" name="departureDate" selected={trip.departureDate} onChange={(date) => handleDepartureDate(index, "departureDate", date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                {errors[index]?.departureDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                            </div>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger me-2 mt-3"
                                    onClick={() => removeTrip(index)}
                                >
                                    REMOVE
                                </button>
                            )}
                            {index === trips.length - 1 && (
                                <button type="button" className="btn btn-sm btn-primary mt-3" onClick={addTrip}>
                                    ADD CITY
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                <div className="row gy-3 gx-lg-2 gx-3">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                        <h6 className="text-dark">TRAVELLERS</h6>
                        <div className="form-group mb-0">
                            <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}><i className="fas fa-users me-2" />{adults + " Adults, " + kids + " Kids, " + infants + " Infants"}</button>
                                <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                    <div>
                                        <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                        <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Adults</span>
                                        <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                    </div>
                                    <div>
                                        <span className="guests-input__ctrl minus" id="children-subs-btn" onClick={handleDecreaseKids}><i className="fa-solid fa-minus" /></span>
                                        <span className="guests-input__value"><span defaultValue={0} readOnly value={kids} name="kids">{kids}</span>Kids</span>
                                        <span className="guests-input__ctrl plus" id="children-add-btn" onClick={handleIncreaseKids}><i className="fa-solid fa-plus" /></span>
                                    </div>
                                    <div>
                                        <span className="guests-input__ctrl minus" id="room-subs-btn" onClick={handleDecreaseInfants}><i className="fa-solid fa-minus" /></span>
                                        <span className="guests-input__value"><span defaultValue={0} readOnly value={infants} name="infants">{infants}</span>Infants</span>
                                        <span className="guests-input__ctrl plus" id="room-add-btn" onClick={handleIncreaseInfants}><i className="fa-solid fa-plus" /></span>
                                    </div>
                                    <ul className="flx-start mb-sm-0 m-3" style={{ textAlign: "start" }}>
                                        <li className="mb-2">
                                            <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="ECONOMY" onChange={e => handleClassType(e)} value="ECONOMY" checked={cabinClass === "ECONOMY" ? true : false} />
                                            <label className="form-check-label" htmlFor="return">ECONOMY</label>
                                        </li>
                                        <li className="mb-2">
                                            <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="BUSINESS" onChange={e => handleClassType(e)} value="BUSINESS" checked={cabinClass === "BUSINESS" ? true : false} />
                                            <label className="form-check-label" htmlFor="return">BUSINESS</label>
                                        </li>
                                        <li className="mb-2">
                                            <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="FIRST" onChange={e => handleClassType(e)} value="FIRST" checked={cabinClass === "FIRST" ? true : false} />
                                            <label className="form-check-label" htmlFor="return">FIRST</label>
                                        </li>
                                        <li className="mb-2">
                                            <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="PREMIUM_ECONOMY" onChange={e => handleClassType(e)} value="PREMIUM_ECONOMY" checked={cabinClass === "PREMIUM_ECONOMY" ? true : false} />
                                            <label className="form-check-label" htmlFor="return">PREMIUM ECONOMY</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 px-1" style={{ paddingTop: "28px" }}>
                        <button type="button" onClick={e => handleSearch(e)} className="btn btn-primary maincolor full-width fw-medium"><i className="fa-solid fa-magnifying-glass me-2"></i>SEARCH</button>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                <div className="flx-start mb-sm-0 mb-2">
                    <div className="form-check form-check-inline">
                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preferred" id="REGULAR" onChange={e => handlePrefferedType(e)} value="REGULAR" checked={preffered === "REGULAR" ? true : false} />
                        <label className="form-check-label" htmlFor="return">Regular</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preferred" id="STUDENT" onChange={e => handlePrefferedType(e)} value="STUDENT" checked={preffered === "STUDENT" ? true : false} />
                        <label className="form-check-label" htmlFor="oneway">Student</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preffered" id="SENIOR_CITIZEN" onChange={e => handlePrefferedType(e)} value="SENIOR_CITIZEN" checked={preffered === "SENIOR_CITIZEN" ? true : false} />
                        <label className="form-check-label" htmlFor="oneway">Senior Citizen</label>
                    </div>
                </div>
            </div>
        </>
    );
}